import React, { Fragment } from "react";
import BlockContent from "@sanity/block-content-to-react";
import css from "./Contact.module.css";

interface iContact {
  data: any;
}

export const Contact = ({ data }: iContact) => {
  const { title, content } = data;

  return (
    <main>
      <div className={css.contactRow}>
        <div>
          <h1>{title}</h1>
          <BlockContent blocks={content} />
        </div>
        <div className={css.contactBox}>
          <p>
            <i className={[css.fa, "fa", "fa-phone"].join(" ")}></i>
            020 3725 5840
          </p>
          <p>
            <i className={[css.fa, "fa", "fa-envelope"].join(" ")}></i>
            <a href="mailto:info@profileproductions.co.uk"> info@profileproductions.co.uk</a>
          </p>
        </div>
      </div>
      <div className="iframe-wrapper">
        <iframe
          title="Profile Productions Ltd location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d620.9818165907336!2d-0.32542873033168096!3d51.49620219823817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760d3411057b9b%3A0xbb933aef5d9c5ee0!2sBoundary%20House!5e0!3m2!1sen!2suk!4v1738067881470!5m2!1sen!2suk"
          width="100%"
          height="300"
          style={{ border: 0 }}></iframe>
      </div>
    </main>
  );
};

export default Contact;
